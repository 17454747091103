import classNames from "classnames"
import type { MotionValue } from "framer-motion"
import { motion, useScroll, useTransform } from "framer-motion"
import type { RefObject } from "react"
import React from "react"

import type { PNImageProps } from "@bounce/web-components"
import { Button, PNImage } from "@bounce/web-components"

import styles from "./assets/hero.module.css"

import OBJ_BACKPACK from "./assets/backpack.png"
import OBJ_CAMERA from "./assets/camera.png"
import FLOATING_IMG1 from "./assets/hero-1.jpg"
import FLOATING_IMG2 from "./assets/hero-2.jpg"
import FLOATING_IMG3 from "./assets/hero-3.jpg"
import OBJ_INFLATABLE from "./assets/inflatable.png"
import OBJ_LUGGAGE from "./assets/luggage.png"
import WalkIcon from "@bounce/assets/icons/Walk.svg"

type ParallaxedProps = {
  motioned: MotionValue<number>
}
type Props = {
  ctaHref?: string | undefined
  ctaOnClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined
}

export const Hero = React.forwardRef<HTMLDivElement, Props>(({ ctaHref, ctaOnClick }: Props, signal) => {
  const { scrollYProgress } = useScroll({
    target: signal as RefObject<HTMLDivElement>,
    offset: ["start 100px", "end start"],
  })

  return (
    <div
      ref={signal}
      className={classNames(
        "relative flex h-screen max-h-[600px] w-full items-center bg-lilac-100 pb-16",
        styles.heroWrapper,
      )}>
      <ParalaxedBackground motioned={scrollYProgress} />
      <ParalaxedItems motioned={scrollYProgress} />
      <div className="relative mx-auto max-w-sm p-6 lg:max-w-2xl">
        <h1 className="title1 text-center text-primary-900">Change the way people experience cities</h1>
        <Button as="a" href={ctaHref} onClick={ctaOnClick} className="mx-auto mt-8">
          See open roles
        </Button>
      </div>
    </div>
  )
})
Hero.displayName = "Hero"

const ParalaxedItems = ({ motioned }: ParallaxedProps) => {
  const inflatable = useTransform(motioned, [0, 1], [0, 200])
  const camera = useTransform(motioned, [0, 1], [0, 100])
  const backpack = useTransform(motioned, [0, 1], [0, -30])
  const luggage = useTransform(motioned, [0, 1], [0, -130])

  return (
    <>
      <div className="absolute inset-0 mx-auto w-full max-w-screen-2xl md:hidden">
        <motion.div className="absolute right-[15%] top-[-5%]" style={{ rotate: 8, y: inflatable }}>
          <FloatingObject src={OBJ_INFLATABLE} alt="inflatable floating" width={150} height={150} />
        </motion.div>
        <motion.div className="absolute left-[-5%] top-[5%]" style={{ y: camera }}>
          <FloatingObject src={OBJ_CAMERA} alt="camera floating" width={126} height={95} />
        </motion.div>
        <motion.div className="absolute left-[-4%] top-[50%]" style={{ rotate: 0, y: backpack }}>
          <FloatingObject src={OBJ_BACKPACK} alt="backpack floating " width={114} height={145} />
        </motion.div>
        <motion.div className="absolute bottom-[-10%] left-10" style={{ rotate: 15, y: luggage }}>
          <FloatingObject src={OBJ_LUGGAGE} alt="luggage floating" width={100} height={180} />
        </motion.div>
      </div>
      <div className="absolute inset-0 mx-auto hidden w-full max-w-screen-2xl md:block">
        <motion.div className="absolute left-[18%] top-0" style={{ rotate: 8, y: inflatable }}>
          <FloatingObject src={OBJ_INFLATABLE} alt="inflatable floating" width={170} height={170} />
        </motion.div>
        <motion.div className="absolute left-[10%] top-[35%]" style={{ y: camera }}>
          <FloatingObject src={OBJ_CAMERA} alt="camera floating" width={142} height={107} />
        </motion.div>
        <motion.div className="absolute left-0 top-0" style={{ rotate: 0, y: backpack }}>
          <FloatingObject src={OBJ_BACKPACK} alt="backpack floating " width={150} height={191} />
        </motion.div>
        <motion.div className="absolute bottom-[-15%] right-[5%]" style={{ rotate: 15, y: luggage }}>
          <FloatingObject src={OBJ_LUGGAGE} alt="luggage floating" width={130} height={230} />
        </motion.div>
      </div>
    </>
  )
}

const ParalaxedBackground = ({ motioned }: ParallaxedProps) => {
  const sm = useTransform(motioned, [0, 1], [10, 250])
  const walk = useTransform(motioned, [0, 1], [0, 150])
  const walk_pill = useTransform(motioned, [0, 1], [0, -50])
  const botRight = useTransform(motioned, [0, 1], [30, 100])
  const topRight = useTransform(motioned, [0, 1], [0, -100])

  return (
    <>
      <div className="absolute inset-0 mx-auto w-full max-w-screen-2xl overflow-hidden md:hidden">
        <motion.div className="absolute -right-8 bottom-0" style={{ y: sm }}>
          <FloatingImage src={FLOATING_IMG3} alt="office-pic" width={300} height={180} />
        </motion.div>
      </div>
      <div className="absolute inset-0 mx-auto hidden w-full max-w-screen-2xl overflow-hidden md:block">
        <motion.div className="absolute bottom-0 left-0" style={{ y: walk }}>
          <FloatingImage src={FLOATING_IMG1} alt="office-pic" width={450} height={272} />
          <motion.div
            style={{ y: walk_pill }}
            className="center absolute -right-10 top-1/2 flex items-center rounded-full bg-orange-700 px-4 py-2 text-sm text-white">
            <WalkIcon className="-ml-2 mr-1 h-5 w-5" />5 min
          </motion.div>
        </motion.div>
        <motion.div className="absolute bottom-0 left-1/2" style={{ x: "50%", y: botRight }}>
          <FloatingImage src={FLOATING_IMG2} alt="office-pic" width={330} height={200} />
        </motion.div>
        <motion.div className="absolute left-1/2 top-0" style={{ x: "70%", y: topRight }}>
          <FloatingImage src={FLOATING_IMG3} alt="office-pic" width={473} height={285} />
        </motion.div>
      </div>
    </>
  )
}

const FloatingImage = ({ src, alt, width, height }: Pick<PNImageProps, "src" | "alt" | "width" | "height">) => (
  <PNImage
    src={src}
    alt={alt}
    style={{ height, width }}
    className="pointer-events-none select-none rounded-2xl bg-neutral-100 object-cover object-center"
    width={width}
    height={height}
    data-chromatic="ignore"
  />
)

const FloatingObject = ({ src, alt, width, height }: Pick<PNImageProps, "src" | "alt" | "width" | "height">) => (
  <PNImage
    src={src}
    alt={alt}
    style={{ height, width }}
    className="pointer-events-none select-none object-contain object-center"
    width={width}
    height={height}
    data-chromatic="ignore"
  />
)

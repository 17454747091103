import classNames from "classnames"
import React from "react"

import type { Nullable } from "@bounce/util"

import styles from "./assets/testimonial.module.css"
import type { PNImageProps } from "../../PNImage/PNImage"
import { PNImage } from "../../PNImage/PNImage"

import QuotesIcon from "./assets/quote.svg"

export type TestimonialProps = {
  src?: Nullable<PNImageProps["src"]>
  name?: Nullable<string>
  nameSubtitle?: Nullable<string>
  text?: Nullable<string>
  isInteractive?: boolean
}

export const Testimonial = ({ src, name, nameSubtitle, text, isInteractive }: TestimonialProps) => {
  return (
    <div className="relative flex h-full min-w-64 flex-col justify-between overflow-hidden rounded-2xl bg-primary-900  text-lilac-100 md:flex-row  md:justify-center">
      <div className={classNames("absolute inset-0 z-0 bg-primary-800", styles.testimonialBgMask)}></div>
      <div
        className={classNames("relative w-full px-8 pt-16 md:px-20 md:py-28 md:pb-16", isInteractive && "sm:pb-[1px]")}>
        <QuotesIcon className="mb-8" />
        <p className="paragraph1 mb-8">{text}</p>
        <p className="label1 mb-2">{name}</p>
        <p className="paragraph2">{nameSubtitle}</p>
      </div>
      <div
        className={classNames(
          "relative -mt-8 w-full max-w-lg translate-x-8 translate-y-16 self-end md:basis-3/4",
          styles.imageMask,
        )}>
        {!!src && (
          <PNImage
            src={src}
            unoptimized
            imgixParams={{ fit: "crop", w: 1000, h: 1000 }}
            className="w-full object-cover object-center"
            data-chromatic="ignore"
          />
        )}
      </div>
    </div>
  )
}
